@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
    body {
        background-color: #fff !important;
        font-family: 'Poppins', sans-serif !important;
    }
    
    .Jumbotron {
        margin-top: 50% !important;
        margin: auto;
        width: 85%;
        background-color: #fff !important;
        border-radius: 20px !important;
    }
    
    @media only screen and (min-width: 768px) {
        .myJumbotron {
            margin-top: 10% !important;
            margin: auto;
            width: 60%;
            background-color: #DD926A !important;
            border-radius: 20px !important;
        }
    }
    
    .myEditCourtButton {
        background-color: #D45031 !important;
        width: 200px !important;
        border: none !important;
        border-radius: 50px !important;
    }
    
    .mySearchBar {
        background-color: #DD926A !important;
    }
    
    .mySearchBarWidget {
        box-shadow: none !important;
        border-color: #DD926A !important;
        border-style: solid !important;
    }
    
    .mySearch {
        color: #fff !important;
    }
    
    .myInputField {
        border: none;
        border-radius: 50px !important;
        padding-left: 10px !important;
    }
    
    input[type="text"],
    textarea {
        background-color: #d1d1d1;
    }
    
    input[type="password"],
    textarea {
        background-color: #d1d1d1;
    }
    
    .myLabel {
        display: flex;
    }
    
    .myButton {
        border-radius: 50px !important;
        background-color: #D45031 !important;
        width: 40%;
        border: none !important;
    }
    
    .myButtonForm {
        border-radius: 50px !important;
        background-color: #D45031 !important;
        border: none !important;
        width: 40%;
        margin-left: 20px;
    }
    
    .myButtonFormAdd {
        border-radius: 50px !important;
        background-color: #D45031 !important;
        border: none !important;
        width: 40%;
    }

    .extractReport {
        width: 150px !important;
        height: 40px;
        background-color: #D45031 !important;
        box-shadow: none !important;
        border-radius: 50px !important;
        color: #fff !important;
        border: none !important;
        margin-left: 20px !important;
    }
    .myCircleButtonGreen {
        display:block;
        width:30px;
        height:30px;
        line-height:80px;
        margin-right: 20px !important;
        border-radius: 50%;
        color:#009b24;
        text-align:center;
        text-decoration:none;
        background-color: #009b24 !important;
        font-size:20px;
        font-weight:bold;
    }
    .myCircleButtonRed {
        display:block;
        width:30px;
        height:30px;
        line-height:80px;
        
        border-radius: 50%;
        color:#009b24;
        text-align:center;
        text-decoration:none;
        background-color: #eb0000 !important;
        font-size:20px;
        font-weight:bold;
    }

    .fetchData {
        width: 150px !important;
        height: 40px;
        background-color: #D45031 !important;
        box-shadow: none !important;
        border-radius: 50px !important;
        color: #fff !important;
        border: none !important;
        margin-left: 20px !important;
    }
    
    .myFormControls {
        margin: auto;
    }
    
    .myCoachCard {
        width: auto;
        height: 200px;
        background-color: #6d7fcc;
        border-radius: 30px;
        position: relative;
        background-image: url(/static/media/coach.712b919b.jpg);
        background-position: 50% 50%;
        background-size: cover;
        margin-bottom: 20px;
    }
    
    .myCoachTooltip {
        width: 100%;
        height: 40;
        background-color: #D45031;
        border-radius: 30px;
        position: absolute;
        top: 72%;
    }
    
    .myCoachTitle {
        color: #fff;
        font-weight: 500;
        font-size: x-large;
        text-align: center;
        margin: 14px;
    }
    
    .myTournamentCard {
        width: 100%;
        height: 300px;
        background-color: #6d7fcc;
        border-radius: 30px;
        position: relative;
        margin-bottom: 30px;
        background-image: url(/static/media/tournament.a6c7842a.jpg);
        background-position: 50% 50%;
        background-size: cover;
    }
    
    .myTournamentTooltip {
        width: 75%;
        height: 80px;
        background-color: #D45031;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;
        position: absolute;
        top: 73.8%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .myMssg {
        background-color: #d1d1d1 !important;
        border: none !important;
    }
    
    .myTournamentName {
        color: #fff;
        text-align: center;
        width: 100%;
        padding-top: 6px;
        padding-left: 4px;
        padding-right: 4px;
        position: relative;
    }
    
    @media only screen and (min-width: 768px) {
        .myAvatar {
            border-radius: 50px !important;
            width: 50px !important;
            height: 50px !important;
            background-color: #DD926A;
            background: url(https://images.unsplash.com/photo-1489980557514-251d61e3eeb6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80);
            background-position: 50% 50%;
            background-size: cover;
            border-radius: 50%;
        }
        .mySettingsIcon {
            width: 50px !important;
            height: 50px !important;
            margin-right: 10px !important;
            border-radius: 50% !important;
            margin-top: -20px;
        }
        .myName {
            text-align: left !important;
            font-size: 40px !important;
        }
        .myTitle {
            text-align: left !important;
            font-size: 24px !important;
        }
        .myAvatarAndSettings {
            text-align: end;
        }
    }
    
    .noOverdue {
        border-radius: 40px !important;
        margin: auto !important;
        width: 100%;
        background-color: green !important;
        border: none !important;
        box-shadow: none !important;
    }
    
    .hasOverdue {
        border-radius: 40px !important;
        margin: auto !important;
        width: 100%;
        background-color: red !important;
        border: none !important;
        box-shadow: none !important;
    }
    .isLateCancelled {
        border-radius: 40px !important;
        margin: auto !important;
        width: 100%;
        background-color: red !important;
        border: none !important;
        box-shadow: none !important;
    }

    .isNoShow{
        border-radius: 40px !important;
        margin: auto !important;
        width: 100%;
        background-color: black !important;
        border: none !important;
        box-shadow: none !important;
    }
    
    .myEditIcon {
        background-color: transparent;
        border: none !important;
        color: green !important;
    }
    
    .myDeleteIcon {
        color: red !important;
    }
    
    .myMoreIcon {
        color: black !important;
    }
    
    .myAvatar {
        border-radius: 30px !important;
        width: 30px !important;
        height: 30px !important;
        background-color: #DD926A;
        display: inline-block;
        background: url(https://images.unsplash.com/photo-1489980557514-251d61e3eeb6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80);
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 50%;
    }
    
    .mySettingsIcon {
        width: 30px !important;
        height: 30px !important;
        display: inline-block !important;
        margin-right: 10px !important;
        margin-top: -20px;
    }
    
    .myAvatarAndSettings {
        text-align: end;
    }
    
    .settingsName {
        font-size: 40px !important;
        font-weight: 500 !important;
    }
    
    .settingsEmail {
        font-size: 20px !important;
        font-weight: 300 !important;
    }
    
    .myName {
        font-size: 25px;
        text-align: left;
    }
    
    .myTitle {
        font-size: 20px;
        text-align: left;
    }
    
    .mySettingsIcon {
        display: inline-block;
    }
    
    .myCard {
        width: 100%;
        height: 150px;
        border-radius: 30px !important;
        background-color: #DD926A !important;
        display: inline-block;
        margin-top: 20px;
        text-align: center;
        background-image: url(/static/media/orange3.adc7e85e.jpg);
        background-position: 50% 50%;
        background-size: cover;
    }
    
    .myCardContentTitle {
        text-align: center;
        margin-top: 20px;
        color: #fff;
    }
    
    .myCardContentInfo {
        text-align: center;
        margin-top: 30px;
        color: #fff;
    }
    
    .submitButton {
        background-color: #D45031 !important;
        box-shadow: none !important;
        border-radius: 50px !important;
        color: #fff !important;
        border: none !important;
        width: 20% !important;
    }
    
    .rc-menu-button {
        background: transparent !important;
        border: none !important;
        margin-left: 15px !important;
        margin-bottom: 5px;
    }
    
    input {
        border: none;
        padding: 5px !important;
        border-radius: 5px !important;
        outline: none;
        /* test*/
    }
    
    .myInputFieldMsgg {
        height: 200px !important;
    }

    .moreInfoButton {
        background-color: #D45031 !important;
        box-shadow: none !important;
        border-radius: 50px !important;
        color: #fff !important;
        border: none !important;
    }
    
    .addCourtButton {
        width: 250px !important;
        height: 40px;
        background-color: #D45031 !important;
        box-shadow: none !important;
        border-radius: 50px !important;
        color: #fff !important;
        border: none !important;
        margin-left: 20px !important;
    }
    
    .addUserButton {
        width: 150px !important;
        height: 40px;
        background-color: #D45031 !important;
        box-shadow: none !important;
        border-radius: 50px !important;
        color: #fff !important;
        border: none !important;
        margin-left: 20px !important;
        margin-bottom: 20px;
    }
    
    .myAvatarCircle {
        width: 300px !important;
        height: 300px !important;
        border-radius: 150px !important;
        background-color: #6d7fcc !important;
        margin: auto;
        margin-bottom: 25px !important;
    }
    
    .mySettingsJumbotron {
        background-color: #D45031 !important;
        color: #fff !important;
        margin-top: 100px !important;
        border-radius: 20px !important;
    }
    
    .settingsContent {
        margin: auto;
        text-align: center;
        position: relative;
        top: -150px;
    }
    
    .myCourtsCards {
        margin: 20px;
    }
    
    .popup-content {
        border-radius: 25px !important;
        padding: 25px !important;
        height: 400px !important;
        overflow: auto !important;
    }
    
     ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    a {
        color: var(--text-color);
        text-decoration: none;
        ;
    }
    /* Top Navigation Bar */
    /* <nav> */
    
    .navbar {
        height: var(--nav-size);
        background-color: var(--bg);
        padding: 0 1rem;
        border-bottom: var(--border);
    }
    /* <ul> */
    
    .navbar-nav {
        max-width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
    }
    /* <li> */
    
    .nav-item {
        width: calc(var(--nav-size) * 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* Icon Button */
    
    .icon-button {
        --button-size: calc(var(--nav-size) * 0.5);
        width: var(--button-size);
        height: var(--button-size);
        background-color: #484a4d;
        border-radius: 50%;
        padding: 5px;
        margin: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: -webkit-filter 300ms;
        transition: filter 300ms;
        transition: filter 300ms, -webkit-filter 300ms;
    }
    
    .icon-button:hover {
        -webkit-filter: brightness(1.2);
                filter: brightness(1.2);
    }
    
    .icon-button svg {
        fill: var(--text-color);
        width: 20px;
        height: 20px;
    }
    /* Dropdown Menu */
    
    .menu {
        width: 100%;
    }
    
    .myInputField::-webkit-input-placeholder {
        padding-left: 10px !important;
    }
    
    .myInputField::placeholder {
        padding-left: 10px !important;
    }
    
    #inputVacationPercentage {
        margin-left: 10px !important;
        margin-bottom: 10px !important;
    }
    
    .menu-item {
        height: 50px;
        display: flex;
        align-items: center;
        border-radius: var(--border-radius);
        transition: background var(--speed);
        padding: 0.5rem;
    }
    
    .menu-item .icon-button {
        margin-right: 0.5rem;
    }
    
    .menu-item .icon-button:hover {
        -webkit-filter: none;
                filter: none;
    }
    
    .menu-item:hover {
        background-color: #525357;
    }
    
    .icon-right {
        margin-left: auto;
    }
    /* CSSTransition classes  */
    
    .menu-primary-enter {
        position: absolute;
        -webkit-transform: translateX(-110%);
                transform: translateX(-110%);
    }
    
    .menu-primary-enter-active {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
        transition: all var(--speed) ease;
    }
    
    .menu-primary-exit {
        position: absolute;
    }
    
    .menu-primary-exit-active {
        -webkit-transform: translateX(-110%);
                transform: translateX(-110%);
        transition: all var(--speed) ease;
    }
    
    .menu-secondary-enter {
        -webkit-transform: translateX(110%);
                transform: translateX(110%);
    }
    
    .menu-secondary-enter-active {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
        transition: all var(--speed) ease;
    }
    
    .menu-secondary-exit-active {
        -webkit-transform: translateX(110%);
                transform: translateX(110%);
        transition: all var(--speed) ease;
    }
    
    .myTopBar {
        margin: 0px !important;
        max-width: 100% !important;
        margin-bottom: 30px !important;
    }
    
    .myMainCard {
        width: 100%;
        height: 300px;
        display: inline-block !important;
        border-radius: 30px !important;
        background-color: #DD926A !important;
        position: relative;
        margin-top: 20px;
        background-image: url(/static/media/academies.a210c5d9.jpg);
        background-position: 50% 50%;
        background-size: cover;
    }
    
    .myMainCardTitle {
        text-align: center;
    }
    
    .myImages {
        width: 100%;
        height: 300px;
        overflow: hidden;
        border-radius: 30px !important;
    }
    
    .myToolTip {
        width: 50%;
        height: 50px;
        background-color: #D45031;
        border-radius: 0px 20px 0px 30px !important;
        position: absolute;
        top: 83.47%;
        right: 50%;
    }
    
    .myDeleteToolTip {
        width: 35%;
        height: 30px;
        background-color: #D45031;
        border-radius: 0px 20px 0px 30px !important;
        position: absolute;
        bottom: 540%;
        left: 165%;
    }
    
    .myTrashIcon {
        color: #fff;
        margin: auto;
        width: 20%;
    }
    
    .myTopBar {
        margin-top: 20px !important;
    }
    
    .myMainCardTitle {
        color: #fff;
        width: 100%;
        margin-top: 9px;
    }
    
    .myNav {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }
    
    .myHomeTest {
        position: relative;
    }
    /*
    DEMO STYLE
*/
    
    .myTag {
        margin-left: 10px;
    }
    
    .myIcon {
        margin-right: 10px;
        width: 20px;
        margin-bottom: 6px;
    }
    
    a,
    a:hover,
    a:focus {
        color: inherit;
        text-decoration: none;
        transition: all 0.3s;
    }
    
    .navbar {
        padding: 15px 10px;
        background: #fff;
        border: none;
        border-radius: 0;
        margin-bottom: 40px;
    }
    
    .navbar-btn {
        box-shadow: none;
        outline: none !important;
        border: none;
    }
    
    .myLogoNav {
        width: 20%;
        margin-bottom: 8px !important;
    }
    
    .myToggler {
        background-color: #D45031 !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important
    }
    
    .line {
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed #ddd;
        margin: 40px 0;
    }
    /* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
    
    .wrapper {
        display: flex;
        width: 100%;
        align-items: stretch;
    }
    
    #sidebar {
        min-width: 250px;
        max-width: 250px;
        background: #DD926A;
        color: #fff;
        transition: all 0.3s;
    }
    
    #sidebar.active {
        margin-left: -250px;
    }
    
    #sidebar .sidebar-header {
        padding: 20px;
        background: #D45031;
    }
    
    #sidebar ul.components {
        padding: 20px 0;
    }
    
    #sidebar ul p {
        color: #fff;
        padding: 10px;
    }
    
    #sidebar ul li a {
        padding: 10px;
        font-size: 1.1em;
        display: block;
    }
    
    #myArrow {
        float: right;
        margin-right: 10px;
        width: 30px;
        height: 30px;
    }
    
    #sidebar ul li a:hover {
        color: #7386D5;
        background: #D45031;
    }
    
    a[data-toggle="collapse"] {
        position: relative;
    }
    
    a {
        color: #fff !important;
        text-decoration: none !important;
        font-family: 'Poppins', sans-serif;
    }
    
    .dropdown-toggle::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }
    
    ul.CTAs {
        padding: 20px;
    }
    
    ul.CTAs a {
        text-align: center;
        font-size: 0.9em !important;
        display: block;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    
    a.article,
    a.article:hover {
        background: #D45031 !important;
        color: #fff !important;
    }
    
    .myIconBot {
        margin-right: 10px;
        color: #fff !important;
    }
    /* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
    
    #content {
        width: 100%;
        padding: 20px;
        min-height: 100vh;
        transition: all 0.3s;
    }
    
    .myNavTitle {
        font-size: x-large;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 20px !important;
    }

    .v2-button {
        background-color: #D45031 !important;
        box-shadow: none !important;
        border-radius: 50px !important;
        color: #fff !important;
        border: none !important;
        margin-left: 20px !important;
    }

    .v2-icon {
        color: #fff !important;
        height: 30px;
        width: 100%;
        margin-right: 20px;
        margin-bottom: 6px;
    }

    .login-error {
        color: red;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        text-align: center;
    }
    
    .mySecondaryCard {
        width: 350px;
        height: 250px;
        background-color: #6d7fcc;
        border-top-right-radius: 40px;
        position: relative;
    }
    
    .mySecondaryTooltip {
        background-color: #D45031;
        border-top-right-radius: 40px;
        width: 200px;
        height: 50px;
        position: absolute;
        top: 80%;
    }
    
    #dropdown-basic-button {
        width: 250px;
        border-radius: 50px !important;
        background-color: #D45031;
        border: none;
        box-shadow: none !important;
    }
    
    .myDropDownText {
        color: black !important;
    }
    /* ---------------------------------------------------
    LOADING STYLE
----------------------------------------------------- */
    
    .loadingWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    /* ---------------------------------------------------
    EDIT USER MODAL STYLE
----------------------------------------------------- */
    
    .modal {
        font-size: 12px;
    }
    
    .modal>.modal-header {
        width: 100%;
        border-bottom: 1px solid gray;
        font-size: 18px;
        text-align: center;
        padding: 5px;
    }
    
    .modal>.modal-content {
        width: 100%;
        padding: 10px 5px;
    }
    
    .modal>.modal-actions {
        width: 100%;
        padding: 10px 5px;
        margin: auto;
        text-align: center;
    }
    
    .modal>.modal-close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #ffffff;
        border-radius: 18px;
        border: 1px solid #cfcece;
    }
    /* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
    
    @media (max-width: 768px) {
        #sidebar {
            margin-left: -250px;
        }
        #sidebar.active {
            margin-left: 0;
        }
        #sidebarCollapse span {
            display: none;
        }
        #myMainContent.none {
            display: none;
        }
        .myAvatarCircle {
            width: 100px !important;
            height: 100px !important;
            border-radius: 50px !important;
            background-color: #6d7fcc !important;
            margin: auto;
            margin-bottom: 15px !important;
        }
        .mySettingsJumbotron {
            background-color: #D45031 !important;
            color: #fff !important;
            margin-top: 60px !important;
            border-radius: 20px !important;
        }
        .settingsContent {
            margin: auto;
            text-align: center;
            position: relative;
            top: -75px;
        }
        .settingsName {
            font-size: 25px !important;
            font-weight: 500 !important;
        }
        .settingsEmail {
            font-size: 15px !important;
            font-weight: 300 !important;
        }
        .myToolTip {
            width: 60%;
            height: 50px;
            background-color: #D45031;
            border-radius: 0px 20px 0px 30px !important;
            position: absolute;
            top: 83.47%;
            right: 40%;
        }
        .submitButton {
            background-color: #D45031 !important;
            box-shadow: none !important;
            border-radius: 50px !important;
            color: #fff !important;
            border: none !important;
            width: 50% !important;
        }
        .myDeleteToolTip {
            width: 35%;
            height: 30px;
            background-color: #D45031;
            border-radius: 0px 30px 0px 30px !important;
            position: absolute;
            bottom: 540%;
            left: 131.5%;
        }
    }

    /* ---------------------------------------------------
    Other
----------------------------------------------------- */

    .myRow {
        padding-left: 40px;
        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
.to-add-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 20px;
}

.to-add-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.to-add-remove-button {
    background-color: white !important;
    color: red !important;
    border: none !important;
    margin-left: 10px;
    margin-right: 10px;
}

.release-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #d25138;
    border-radius: 5px;
    padding: 10px;
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
}

.release-button:hover {
    background-color: #dc926e;
}

.challenges-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.challenge-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 20px;
    padding-left: 50px;
    margin-bottom: 20px;
}

.ch-card.title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.ch-card.add-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #d25138;
    border-radius: 30px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
}

.ch-card.add-button:hover {
    background-color: #dc926e;
}

.ch-card.row {
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ch-card.number {
    width: 60px;
    border: 2px solid #dc926e;
    margin-left: 5px;
    margin-right: 5px;
}

.ch-card.time {
    border: 2px solid #dc926e !important;
    margin-left: 5px;
    margin-right: 5px;
}

.ch-card.text {
    border: 2px solid #dc926e;
    margin-left: 5px;
    margin-right: 5px;
    width: 60%;
}

.ch-card.select {
    width: 150px;
    border: 2px solid #dc926e;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
}
