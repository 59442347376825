.to-add-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 20px;
}

.to-add-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.to-add-remove-button {
    background-color: white !important;
    color: red !important;
    border: none !important;
    margin-left: 10px;
    margin-right: 10px;
}

.release-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #d25138;
    border-radius: 5px;
    padding: 10px;
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
}

.release-button:hover {
    background-color: #dc926e;
}

.challenges-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.challenge-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 20px;
    padding-left: 50px;
    margin-bottom: 20px;
}

.ch-card.title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.ch-card.add-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #d25138;
    border-radius: 30px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
}

.ch-card.add-button:hover {
    background-color: #dc926e;
}

.ch-card.row {
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ch-card.number {
    width: 60px;
    border: 2px solid #dc926e;
    margin-left: 5px;
    margin-right: 5px;
}

.ch-card.time {
    border: 2px solid #dc926e !important;
    margin-left: 5px;
    margin-right: 5px;
}

.ch-card.text {
    border: 2px solid #dc926e;
    margin-left: 5px;
    margin-right: 5px;
    width: 60%;
}

.ch-card.select {
    width: 150px;
    border: 2px solid #dc926e;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
}